import { ToggleModel } from '@/models/ToggleModel';

import FixationModalStore, { IFixationModalStore } from '../FixationModalStore';
import { IRootStore } from '../RootStore';

import { ILeadsStore, LeadModalStore } from './LeadModalStore';

export interface IModalsStore {
  /** Модалка списка уведомлений */
  notificationListModal: ToggleModel;
  /** Модалка с информацией о назначении встречи */
  meetingNoticeModal: ToggleModel;
  /** Модалка с детальной информацией о заявке */
  leadModalStore: ILeadsStore;
  /** Модалка фиксации клиента */
  fixationModalStore: IFixationModalStore;
}

type Params = {
  rootStore: IRootStore;
};

export class ModalsStore implements IModalsStore {
  readonly notificationListModal = new ToggleModel();
  readonly meetingNoticeModal = new ToggleModel();
  readonly leadModalStore = new LeadModalStore();
  readonly fixationModalStore: FixationModalStore;

  constructor({ rootStore }: Params) {
    this.fixationModalStore = new FixationModalStore({ rootStore });
  }
}
