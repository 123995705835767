import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { noop } from '@kts-front/utils';

import Button, { ButtonProps, ButtonSize, ButtonType } from '@/components/Button';
import IconButton from '@/components/IconButton';
import Typo, { TypoView } from '@/components/Typo';
import { KeyboardKey, useKeyDown } from '@/hooks/useKeyDown';
import { useUiStore } from '@/stores/global/RootStore';
import { useDragModalBottom } from '@/utils/useDragModalBottom';
import { useIsPWAOnIOS } from '@/utils/useIsPWA';

import ErrorNotice, { ErrorNoticeProps } from '../ErrorNotice/ErrorNotice';

import s from './RightWindow.module.scss';

type RightWindowButtonProps = ButtonProps<string> & { text: string };

export type RightWindowProps = React.PropsWithChildren<{
  className?: string;
  contentClassName?: string;
  showCloseButton?: boolean;
  onClose: VoidFunction;
  primaryButton?: RightWindowButtonProps;
  secondaryButton?: RightWindowButtonProps;
  header?: React.ReactNode;
  withBackdrop?: boolean;
  childrenContainerRef?: React.RefObject<HTMLDivElement>;
  isScrollable?: boolean;
}> &
  ErrorNoticeProps & {
    opened: boolean;
  };

const RightWindow: React.FC<RightWindowProps> = ({
  children,
  className,
  contentClassName,
  showCloseButton = true,
  onClose,
  primaryButton,
  secondaryButton,
  header,
  withBackdrop = true,
  error,
  onCloseError,
  childrenContainerRef,
  isScrollable = true,
  opened,
}) => {
  const { isPhone } = useUiStore();
  const isPWAOnIOS = useIsPWAOnIOS();

  const { dragModalBottom, swipeY, isDragging, headerRef } = useDragModalBottom(onClose, isScrollable);

  const modalCls = cn(s.content, withBackdrop && s.content_shadowNone, className, isPWAOnIOS && s.content_pwaIOS);

  useKeyDown({
    key: KeyboardKey.enter,
    checks: [opened, !!primaryButton?.onClick],
    func: primaryButton?.onClick || noop,
  });

  return (
    <div
      className={modalCls}
      style={{
        transform: `translateY(${swipeY}px)`,
        transition: isDragging ? 'none' : 'transform 0.3s ease',
      }}
      {...dragModalBottom()}
    >
      {header && isPhone && (
        <div ref={headerRef} className={s.content_header}>
          {typeof header === 'string' ? (
            <Typo className={s['content_header-title']} view={TypoView.h2}>
              {header}
            </Typo>
          ) : (
            header
          )}
        </div>
      )}
      <div ref={childrenContainerRef} className={cn(s.content_children, contentClassName)}>
        {header && !isPhone && (
          <Typo className={s.content_title} view={TypoView.h2}>
            {header}
          </Typo>
        )}
        {children}
      </div>
      <div className={s.content__footer}>
        {error && <ErrorNotice className={s['content__error-notice']} error={error} onCloseError={onCloseError} />}
        <div className={cn(s.content_buttons, !primaryButton && !secondaryButton && s.content_buttons_hidden)}>
          {primaryButton && (
            <Button {...primaryButton} size={isPhone ? ButtonSize.medium : ButtonSize.large} full>
              {primaryButton.text}
            </Button>
          )}
          {secondaryButton && (
            <Button
              {...secondaryButton}
              size={isPhone ? ButtonSize.medium : ButtonSize.large}
              full
              view={secondaryButton.view || ButtonType.secondary}
            >
              {secondaryButton.text}
            </Button>
          )}
        </div>
      </div>
      {showCloseButton && (
        <IconButton
          className={s.content_closeIcon}
          iconName="IconClose"
          onClick={onClose}
          size={44}
          color={withBackdrop && !isPhone ? 'white' : 'grey-main'}
          aria-label="закрыть"
        />
      )}
    </div>
  );
};

export default observer(RightWindow);
