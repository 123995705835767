import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Popup from 'reactjs-popup';

import { useToggleState } from '@kts-front/hooks';

import OptionInfiniteList from '@/components/form/OptionInfiniteList';
import PhoneField from '@/components/form/PhoneField';
import { ClientSearchOption } from '@/stores/global/FixationModalStore/ClientFixationStore';
import { useFixationModalStore } from '@/stores/global/RootStore';

import ClientSearchItem from './ClientSearchItem';
import EmptyState from './EmptyState';

import s from './ClientsInfiniteList.module.scss';

export type Props = {
  infiniteListHeight: number;
};

const ClientsInfiniteList = ({ infiniteListHeight }: Props): React.JSX.Element => {
  const {
    selectClient,
    clientFixationStore: { clientList, searchOptions, loadMoreClients, changeSearch, clientPhone },
  } = useFixationModalStore();

  const { opened, open, close } = useToggleState();

  const handleChange = React.useCallback(
    (id: string) => {
      selectClient(String(id));
      close();
    },
    [selectClient, close],
  );

  const listItem = React.useCallback(
    (item: ClientSearchOption, checked: boolean, onClick: (id: string) => void) => (
      <ClientSearchItem
        key={item.id}
        className={cn(s.list__item, checked && s.list__item_checked)}
        item={item.client}
        onClick={() => onClick(item.id)}
      />
    ),
    [],
  );

  return (
    <div className={s.wrapper}>
      <Popup
        trigger={
          <PhoneField
            {...clientPhone.props}
            value={clientPhone.value ?? ''}
            focused={opened}
            onChange={changeSearch}
            onFocus={open}
          />
        }
        arrow={false}
        offsetX={-12}
        offsetY={14.5}
        onClose={close}
        onOpen={open}
        open={opened}
        position={'bottom left'}
        nested
      >
        <OptionInfiniteList<string, ClientSearchOption>
          className={s.wrapper}
          open={opened}
          options={searchOptions}
          value={clientPhone.value}
          onClick={handleChange}
          loadMore={loadMoreClients}
          hasMore={clientList.hasMore}
          loading={clientList.list.loadingStage.value}
          renderItem={listItem}
          infiniteListHeight={infiniteListHeight}
          emptyState={<EmptyState close={close} />}
        />
      </Popup>
    </div>
  );
};

export default observer(ClientsInfiniteList);
