import cn from 'classnames';
import * as React from 'react';

import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { ValidatorResult } from '@/types/validator';
import { withRequiredSymbol } from '@/utils/withRequiredSymbol';

import FieldError from '../FieldError';

import s from './BaseField.module.scss';

export type BaseFieldProps = {
  ariaLabel?: string;
  id?: string;
  className?: string;
  disabled?: boolean;
  active?: boolean;
  isError?: boolean;
  error?: ValidatorResult;
  filled?: boolean;
  focused?: boolean;
  required?: boolean;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
  caption?: string;
  subcaption?: string;
  after?: React.ReactElement;
};

type Props = BaseFieldProps & { children: React.ReactElement };

const BaseField = (
  {
    ariaLabel,
    id,
    className,
    children,
    disabled = false,
    active = false,
    isError = false,
    error,
    filled = false,
    focused = false,
    required = false,
    onClick,
    caption,
    subcaption,
    after,
  }: Props,
  ref: React.ForwardedRef<HTMLLabelElement>,
) => {
  const hasError = isError || !!error;
  const component = React.cloneElement(children, {
    className: cn(children.props.className, s.field__inner, caption && s['field__inner-with-caption']),
  });

  const showComponent = focused || active || filled;

  return (
    <div>
      <label
        id={id}
        className={cn(
          s.field,
          className,
          showComponent && s.field_filled,
          disabled && s.field_disabled,
          (active || focused) && s.field_active,
          hasError && s.field_error,
        )}
        ref={ref}
        onClick={onClick}
        aria-label={caption || ariaLabel}
      >
        {caption && (
          <div className={cn(s.field__caption)}>
            <Typo className={s['field__caption-text']} color={hasError ? 'error' : undefined}>
              {withRequiredSymbol(caption, required && !showComponent)}
            </Typo>
          </div>
        )}
        {component}
        {after}
      </label>

      <FieldError error={error} />

      {subcaption && (
        <Typo className={s.field__subcaption} view={TypoView.textSmall} weight={TypoWeight.regular} color={'text-grey'}>
          {subcaption}
        </Typo>
      )}
    </div>
  );
};

export default React.forwardRef(BaseField);
